.dialog {
  height: auto;
  left: 25vw;
  right: 25vw;
  top: 30vh;
  position: absolute;
  background-color: white;
}

@media only screen and (max-width: 767px) {
  .dialog {
    left: 5vw;
    right: 5vw;
  }
}