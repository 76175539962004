.small{
    height: 1rem;
}

.normal{
    height: 2rem;
}

.large{
    height: 3rem;
}