.label {
  font-weight: bold;
}

.maxHeight200px {
  max-height: 200px;
}

.maxWidth9Rem {

}

@media screen and (min-width: 992px) {
  .maxWidth9Rem {
    max-width: 9rem
  }
}


