$blue-color: #003874;
$gold-color: #B49759;
$gray-color: #A9A8A9;

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: $blue-color;
  border-color: $blue-color;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid $blue-color;
  padding: 6px 12px;
  outline: none;
  cursor: pointer;
}

.pagination > li > a:hover {
  background-color: #e9ecef;
  //border-color: #dee2e6;
  color: #dee2e6;
}

.pagination > .active > a:hover {
  color: white;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: $blue-color;
  border-color: $blue-color;
  outline: none;
}

.pagination > li > a, .pagination > li > span {
  color: $blue-color
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: unset
}