.subMenuStyle {
  padding-left: 2.4em;
  padding-right: 2.4em;
  margin-top: 0px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 5px 0px rgba(120, 120, 120, 1);
}

.subMenuStyle a {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.2px;
}

.subMenuLeftPadding {
  padding-left: 174px;
}

@media screen and (max-width: 991px) {
  .subMenuStyle {
    padding-left: 1.9em;
    padding-right: 1.9em;
  }
}
