.container {
  -webkit-box-shadow: 1px 1px 20px -10px rgba(120, 120, 120, 1);
  -moz-box-shadow: 1px 1px 20px -10px rgba(120, 120, 120, 1);
  box-shadow: 1px 1px 20px -10px rgba(120, 120, 120, 1);

  margin-bottom: 3em;
  background-color: #fff;
  min-height: 55vh;
  padding-left: 2em;
  padding-right: 2em;
}

@media screen and (max-width: 576px) {
  .container {
    box-shadow: none;

    padding-left: 1em;
    padding-right: 1em;
  }
}