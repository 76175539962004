$blue-color: #003874;
$light-blue-color: #99CAEA;
$gold-color: #B49759;
$black-color: #000000;
$gray-color: #A9A8A9;
$white-color: #fff;
$menu-link-color: #75C5F8;

$primary: $blue-color;
$info: $light-blue-color;

$navbar-dark-color: $white-color;
$navbar-dark-active-color: $menu-link-color;
$navbar-dark-hover-color: $menu-link-color;

$navbar-light-color: $white-color;
$navbar-light-active-color: $blue-color;
$navbar-light-hover-color: $blue-color;
$navbar-nav-link-padding-x: 20px;
$nav-link-font-size: 18px;

@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #d3ab73;
}

h1, h2, h3 {
  color: #B49759;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
}

a.nav-link.active span.navMainItemLink {
  border-bottom: $menu-link-color 4px solid;
  max-width: max-content;

}

a.nav-link.active span.navItemLink {
  border-bottom: $blue-color 4px solid;
  max-width: max-content;
}

.contentContainer {
  min-height: calc(100vh - 80px);

  -webkit-box-shadow:0 5px 10px 0 rgba(120,120,120,1);
  -moz-box-shadow: 0 5px 10px 0 rgba(120,120,120,1);
  box-shadow: 0 5px 10px 0 rgba(120,120,120,1);
}

.footerPin {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 706px) {
  .contentContainer {
    min-height: calc(100vh - 64px);
  }
}

@media screen and (max-width: 565px) {
  .contentContainer {
    min-height: calc(100vh - 36px);
  }
}

.marginTopAndBottom17vh {
  margin-top: 17vh;
  margin-bottom: 17vh;
}

@media screen and (max-width: 565px) {
  .marginTopAndBottom17vh {
    margin-top: 10vh;
    margin-bottom: 0;
  }
}
