.footerContainer{
  width: 100%;
  height: 80px;
  border-left: none;
  border-right: none;
  position: relative;
  padding: 20px;

  -webkit-box-shadow: 1px 1px 20px -10px rgba(120, 120, 120, 1);
  -moz-box-shadow: 1px 1px 20px -10px rgba(120, 120, 120, 1);
  box-shadow: 1px 1px 20px -10px rgba(120, 120, 120, 1);
}

.videoContainer{
  position: absolute;
  top: 0;
  left:0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video {
  position: absolute;
  z-index: -1;
  //opacity: 0.78;
  width: 100%;
}

@media screen and (max-width: 706px) {
  .footerContainer {
    height: 64px;
    padding: 15px;
  }
}

@media screen and (max-width: 565px) {
  .footerContainer {
    height: 36px;
    padding: 15px;
  }
}
